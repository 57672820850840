.bg-inicio{
    background-image: url("./fondo-inicio.jpg");
    background-size: cover;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}
.bg-tra-primary{
    background-color: rgba(12, 106, 244,0.8);
}

.bg-tra-black{
    background-image: url("./fondo2.png");
    background-size: cover;
    opacity: 0.8;
    height: 100vh;
}


.bg-pri{
    background-color: rgba(12, 106, 244,0.8);
}

.title-bugi{
    margin-top:2rem;
    font-size:5rem;
}

@media (min-width:1500px){
.title-bug{
    font-size:5rem;
}
}
@media (min-width:1500px){
.text-bug{
    font-size:2rem;
}
}



@media (min-width:1080px){
    .title-bug-service{
        font-size:2rem;
    }
}

@media (min-width:1950px){
    .title-bug-service{
        font-size:3rem;
    }
}

@media (min-width:1080px){
    .text-bug-service{
        font-size:1.3rem;
    }
}

@media (min-width:1920px){
    .text-bug-service{
        font-size:2rem;
        text-align: justify;
    }
}
    

*{
    scroll-behavior: smooth;
}