.styleSpinner{
    width: 250px !important;
    height: 250px !important;
}

.contenedor{
    position: relative;
    padding-top: 20%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 20%;
    text-align: center;
}