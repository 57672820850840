.footer{
    
    margin-top: 1rem;
    padding: 1rem;
    background-color:rgb(12, 106, 244);
    color:  white;
    text-align: center;
    font-size: 2rem;
    bottom: 0;
    left: 0;
    width: 100%;
  }